import React from "react";
import Helmet from 'react-helmet'
import Layout from "../../components/Layout";
import Fade from 'react-reveal/Fade';

const IndexPage = () => (
  <Layout>
    <Helmet title={`Popup Arcade | Michelle Pakla`} />
    <div className="content content__project">
      <div className="content__container">
        <h1>Popup Arcade</h1>
        <div className="project__container">
            <div className="project__intro">
                <h2>Introduction</h2>
                <p>The Popup Arcade offers a unique experience by renting out and 
                  delivering arcade games to breweries, company events, and even school dances.</p>
            </div>
            <Fade bottom>
              <div className="project__section">
              <img className="image"
                  sizes="(min-width: 1024px) 1024px, 100vw"
                  srcset="../../img/popup-arcade/popup-project-image-1-500.png 500w,
                          ../../img/popup-arcade/popup-project-image-1-800.png 800w,
                          ../../img/popup-arcade/popup-project-image-1-1200.png 1200w"                  
                  src="../../img/popup-arcade/popup-project-image-1.png"
                  alt="Desktop and mobile comp images" />
              </div>
              <div className="project__section">
                  <h2>Process</h2>
                  <div className="project__table">
                      <span>Role:</span><span>Lead Designer and Front-End Developer</span>
                      <span>Tools:</span><span>Figma, Photoshop, Illustrator</span>
                      <span>Tech:</span><span>Gatsby, Netlify</span>
                  </div>
                  <p>This was a fairly large project since the Popup Arcade did not have any sort of 
                    branding, and so I was tasked with creating the brand. I drew my inspiration from 
                    80's movies and posters, and chose fun neon colors for the logo and branding. For 
                    several of the brewery events, I created promotional gifs based on popular arcade 
                    games with fun callouts to the breweries themselves. I used this site as a learning 
                    opportunity, and built it using the Gatsby headless CMS in the Netlify platform. 
                    This was my first foray into a react framework, and I have since used it for my own 
                    personal projects.
</p>
              </div>
              <div className="project__section">
                <img className="image imagelogo" src="../../img/popup-arcade/popuparcade-logo.svg" alt="Popup Arcade Logo"></img>
              </div>
              <div className="project__section">
                <img className="image"
                  sizes="(min-width: 1024px) 1024px, 100vw"
                  srcset="../../img/popup-arcade/popup-project-image-3-500.png 500w,
                          ../../img/popup-arcade/popup-project-image-3-800.png 800w,
                          ../../img/popup-arcade/popup-project-image-3-1200.png 1200w"                  
                  src="../../img/popup-arcade/popup-project-image-3.png"
                  alt="Popup Arcade style guide" />
              </div>
              <div className="project__section">
              <img className="image"
                  sizes="(min-width: 1024px) 1024px, 100vw"
                  srcset="../../img/popup-arcade/popup-project-image-2-500.png 500w,
                          ../../img/popup-arcade/popup-project-image-2-800.png 800w,
                          ../../img/popup-arcade/popup-project-image-2-1200.png 1200w"                  
                  src="../../img/popup-arcade/popup-project-image-2.png"
                  alt="Game rentals detail page" />
              </div>
              <div className="project__section">
                <img className="image imagegif" src="../../img/popup-arcade/SwampRabbitGalaga.gif" alt="Custom Swamp Rabbit Galaga gif featuring lots of carrots"></img>
              </div>
              <div className="project__section">
                <img className="image imagegif" src="../../img/popup-arcade/modernhops-marioanimation.gif" alt="Custom Modern Hops gif featuring a beer one up"></img>
              </div>
            </Fade>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage